<!-- Navbar (Header) Starts -->
<nav class="header-navbar navbar navbar-expand-lg navbar-primary">
    <div class="container-fluid">
        <div class="navbar-header">
            <button type="button" class="navbar-toggle d-lg-none float-left" data-toggle="collapse" (click)="toggleSidebar()">
                <span class="sr-only">{{'TOGGLE_NAVIGATION' | translate}}</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </button>
            <span class="d-lg-none navbar-right navbar-collapse-toggle">
                <a class="open-navbar-container" (click)="isCollapsed = !isCollapsed"
                   [attr.aria-expanded]="!isCollapsed" aria-controls="navbarSupportedContent">
                    <i class="ft-more-vertical"></i>
                </a>
            </span>
            <!-- <form class="navbar-form navbar-right mt-1" role="search">
                <div class="position-relative has-icon-right">
                    <input type="text" class="form-control round" placeholder="Search">
                    <div class="form-control-position">
                        <i class="ft-search"></i>
                    </div>
                </div>
            </form> -->
            <!-- <h4 class="text-primary">Grocery Dashboard</h4> -->
            <!-- <img class="pietech-logo" [src]="logo"> -->
        </div>
        <div class="navbar-container">

            <div class="collapse navbar-collapse" id="navbarSupportedContent" [ngbCollapse]="isCollapsed">

                <ul class="navbar-nav">
                    <li class="nav-item mr-2  d-none d-lg-block">
                        <select class="form-control" [(ngModel)]="defaultLang" name="defaultLang" (change)="setLang()">
                            <option [value]="lang.languageCode"
                                    *ngFor="let lang of languages">{{lang.languageName}}</option>
                        </select>
                    </li>
                    <!-- li class="nav-item" ngbDropdown [placement]="placement">
                        <a class="nav-link position-relative" id="dropdownBasic2" ngbDropdownToggle>
                            <i class="ft-bell font-medium-3 white darken-4"></i>
                            <span class="notification badge badge-pill  white badge-danger" *ngIf="unreadTotal > 0">{{ unreadTotal }}</span>
                            <p class="d-none">{{'NOTIFICATIONS' | translate}}</p>
                        </a>
                        <div [hidden]="newOrdersList.length === 0" ngbDropdownMenu aria-labelledby="dropdownBasic2" class="notification-dropdown">
                            <div class="noti-list" [perfectScrollbar]>
                                <a class="dropdown-item noti-container py-3 border-bottom border-bottom-white border-bottom-lighten-4" *ngFor="let order of newOrdersList;index as i" (click)="viewOrder(order)">
                                    <i class="ft-bell info float-left d-block font-large-1 mt-1 mr-2"></i>
                                    <span class="noti-wrapper">
                                        <span class="noti-title line-height-1 d-block text-bold-400 info">{{ "ORDER_ID" | translate }}
                                            :{{order.orderID}}</span>
                                    <span class="noti-text">{{ order.title }}</span>
                                    </span>
                                </a>
                            </div>
                            <button class="dropdown-item" style="margin-bottom: 0; width: 100%; text-align: center;" (click)="showAll()"> {{"VIEW_ALL" | translate}} </button>
                        </div>
                        </li-->
                    <li class="nav-item" ngbDropdown [placement]="placement">
                        <a class="nav-link position-relative" id="dropdownBasic3" ngbDropdownToggle>
                            <i class="ft-user font-medium-3 white darken-4"></i>
                            <p class="d-none">{{'USER_SETTINGS' | translate}}</p>
                        </a>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic3" class="text-left">
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="javascript:;" [routerLink]="['/settings/profile']">
                                <i class=" ft-user mr-2 "></i>
                                <span>{{'PROFILE' | translate}}</span>
                            </a>
                            <a class="dropdown-item" href="javascript:;" (click)="signOut()">
                                <i class="ft-power mr-2"></i>
                                <span>{{'LOGOUT' | translate}}</span>
                            </a>
                        </div>
                    </li>


                </ul>
            </div>
        </div>
    </div>
</nav>
<!-- Navbar (Header) Ends -->