<!--Footer Starts-->
<footer>
    <div class="container-fluid p-0">
        <p class="copyright text-center m-0">
            Copyright &copy; {{currentDate | date: 'yyyy'}} <a>{{storeName}}</a>, All rights reserved.
        </p>

    </div>
</footer>
<!--Footer Ends-->
