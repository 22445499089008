import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {CrudService} from "../../service/crud.service";

@Injectable({
	providedIn: "root"
})
export class MerchantService {
	constructor(private crud: CrudService) {
	}

	// Get all merchants
	public getAll(): Observable<any> {
		return this.crud.getData(`/merchants/admin/list`)
	}

	// Get merchant details by Id
	public getById(merchantId: string): Observable<any> {
		return this.crud.getData(`/merchants/${merchantId}`);
	}

	// Get merchant details by Name
	public getByName(merchantName: string): Observable<any> {
		return this.crud.getData(`/merchants/${merchantName}`);
	}

	// Save merchant
	public save(merchantData: any): Observable<any> {
		return this.crud.saveData(`/merchants/create`, merchantData);
	}

	// updates merchant
	public update(merchantId: string, data: any): Observable<any> {
		return this.crud.updateData(`/merchants/update/${merchantId}`, data);
	}
}
