import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { FullLayoutComponent } from "./layouts/full/full-layout.component";
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";

import { Full_ROUTES } from "./shared/routes/full-layout.routes";
import { CONTENT_ROUTES } from "./shared/routes/content-layout.routes";

// import { AuthGuard } from './shared/auth/auth-guard.service';

export function checkIfAuthenticated() {
	return sessionStorage.getItem("token");
}

const appRoutes: Routes = [
	{
		path: "",
		redirectTo: checkIfAuthenticated() ? "products" : "login",
		pathMatch: "full",
	},
	{ path: "", component: FullLayoutComponent, data: { title: "full Views" }, children: Full_ROUTES },        // canActivate: [AuthGuard]
	{ path: "", component: ContentLayoutComponent, data: { title: "content Views" }, children: CONTENT_ROUTES },
];

@NgModule({
	imports: [RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' })],
	exports: [RouterModule]
})

export class AppRoutingModule {

}
