 <div class="custom-loader">
    <div class="loader"></div>
  </div>


<!--   <div>-->
<!--    <div class="loader-overlay">-->
<!--      <div class="loader-bar"></div>-->
<!--      <div class="loader-spinner"></div>-->
<!--    </div>-->
<!--  </div>-->